import {defaultNavigationConfig} from "./omnia/components/layouts/dashboard/config";
import LeonardoPreprocessorSettings from "./components/bh-backend/leonardo-preprocessors";

export const APP_SETTING = {

    /**
     * Domain Setup
     *
     * Specify the core domain and protocol of the application
     */
    domain: "metk.groon.io",
    protocol: "https",
    socket_protocol: 'wss',
    // domain: "localhost:8000",
    // protocol: "http",
    // socket_protocol: 'ws',

    launchpad: [
        {
            label: 'Create Text',
            description: 'Assistenten und individuelle KI Modelle, die Dich unterstützen.',
            value: '/on/beyond-hub/create-text',
            disabled: false,
            permissions: [],
            icon: 'File02',
        },
        {
            label: 'Create Images',
            description: 'Deine Bildsammlung und Verlauf an generierten Bildern und Visuals.',
            value: '/on/beyond-hub/create-images',
            disabled: false,
            permissions: [],
            icon: 'Image03',
        },
        {
            label: 'Projekte',
            description: 'Create Content, erledige Aufgaben, kommentiere und diskutiere.',
            value: '/on/beyond-hub/projects',
            disabled: false,
            permissions: [],
            icon: 'Headphones02',
        },
        {
            label: 'Stundenerfassung',
            description: 'Trage deine TROI Studen unkompliziert ein.',
            value: '/on/beyondh-hub/demo',
            disabled: true,
            permissions: [],
            icon: 'AlarmClock',
        },
        {
            label: 'Messenger',
            description: 'Schreibe mit deinen Kollegen und Teammitgliedern.',
            value: '/groon/chat',
            disabled: false,
            permissions: [],
            icon: 'MessageChatCircle',
        },
        {
            label: 'be.yond Hub',
            description: 'Nutze und erkunde die be.yond Methoden.',
            value: '/on/beyondh-hub/demo',
            disabled: true,
            permissions: [],
            icon: 'Rocket02',
        },
    ],

    /**
     * Sidebar
     *
     * Specify the sidebar items that shall be in the menu of this instance
     * Example:
     *      {
     *          title: 'Some Tool',
     *          path: '/custom-module/some-tool',
     *          icon: <FittingUntitledIcon />,
     *          items: [ ...children of the same type],
     *      },
     */
    navigation: [
        defaultNavigationConfig.home,
        defaultNavigationConfig.creativeStudio,
        {
            title: 'layout.company',
            icon: 'Building01',
            path: null,
            isModules: true,
            items: [
                defaultNavigationConfig.analytics,
                defaultNavigationConfig.resources,
                defaultNavigationConfig.connections,
                defaultNavigationConfig.projects,
                defaultNavigationConfig.marketing,
            ]
        },
    ],

    /**
     * Widgets
     *
     * Specify the widgets that shall be available in the dashboards of this instance
     * Example:
     *      {
     *          name: "Custom Widget",
     *          description: "This is a custom widget that does something",
     *          identifier: "UNIQUE-IDENTIFIER",
     *          module: "Custom Module",
     *          component: ReactComponentToUse
     *      },
     */
    widgets: [],

    /**
     * Services
     *
     * A list of all services (their identifiers!) that are available in this instance
     * Example:
     *     {
     *          name: "Service Name",
     *          identifier: "UNIQUE-IDENTIFIER"
     *     }
     */
    services: [
        {
            name: 'Beyond Hub | Light',
            identifier: 'beyond-hub-light',
            register_redirect: '/beyond-hub'
        },
        {
            name: 'Beyond Hub | Individual',
            identifier: 'beyond-hub-individual',
            register_redirect: '/beyond-hub'
        },
        {
            name: 'Beyond Hub | Occhio',
            identifier: 'beyond-hub-occhio',
            register_redirect: '/beyond-hub'
        },
    ],

    /**
     *
     */
    settings: [
        // {
        //     label: 'Beyond Hub',
        //     value: 'beyond-hub',
        //     icon: 'Globe06',
        //     permissions: ['beyond_hub', 'platform_setup'],
        //     items: [
        //         {
        //             name: 'beyond-hub.image-search-result-count',
        //             label: 'Bilder-Suche Ergebnisse',
        //             description: 'Die Anzahl an Ergebnissen, die bei der Bildersuche zurückgegeben wird.',
        //             type: 'number'
        //         },
        //         {
        //             name: 'beyond-hub.name-chat-delay',
        //             label: 'Chat-Bennenung Verzögerung',
        //             description: 'Die Anzahl an Nachrichten, nach denen der Chat automatisch benannt wird.',
        //             type: 'number'
        //         },
        //         {
        //             name: 'beyond-hub.generated-image-number',
        //             label: 'Bilderzahl bei Generierung (Intern)',
        //             description: 'Die Anzahl an Bildern, die von den Assistenten standardmäßig intern in GROON erzeugt wird',
        //             type: 'number'
        //         },
        //         {
        //             name: 'beyond-hub.generated-image-number-external',
        //             label: 'Bilderzahl bei Generierung (Extern)',
        //             description: 'Die Anzahl an Bildern, die von den Assistenten standardmäßig in be.yond Kontexten erzeugt wird',
        //             type: 'number'
        //         },
        //     ]
        // },
        // {
        //     label: 'Leonardo Preprozessoren',
        //     value: 'beyond-hub-leonardo-preprocessors',
        //     icon: 'CpuChip01',
        //     permissions: ['beyond_hub', 'platform_setup'],
        //     component: <LeonardoPreprocessorSettings />
        // },
    ],

    /**
     * The duration of how long things fade in
     */
    transitionDuration: 500

}
